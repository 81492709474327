$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main.personalDetails{
    padding: 0 10px;
    h3{
        font-weight: 700;
        font-size: $size-L;
        line-height: 19px;
        
        padding: 24px 0 20px 0;
    }

    div.fullName, div.fatherName, div.PAN, div.gender, div.email, div.altNumber, div.marital-status, div.referenceName, div.referenceNumber, div.referenceRelation{
        margin-bottom: 20px;
        p{
            font-weight: 400;
            font-size: $size-M;
            line-height: 17px;
            // margin-top: 20px;
            padding-bottom: 10px;
        }
        input, select{
            padding: 10px;
            // margin-bottom: 20px;

            width: 100%;
            height: 48px;
            
            background: #ECEBFF;
            border-radius: 4px;
            border: none;
        }
    }
    div.PAN{
        // margin-bottom: 12px;
        // input{
        //     // margin-bottom: 8px;
        // }
        span#panFormat{
            font-size: $size-S;
            color: red;
            // margin-bottom: 10px;

            display: none;
        }
    }

    div.gender{
        font-weight: 400;
        font-size: $size-M;
        line-height: 17px;
        padding: 4px 0px 0px 0px;

        p{
            padding-bottom: 16px;
        }
        div.radioOption{
            display: flex;
            align-items: center;
            padding-bottom: 15px;
        }
        div.radioOption:last-child{
            padding-bottom: 0;
        }
        input{
            height: 24px;
            width: 24px;
            border: 2px solid #5E5E5E;
            accent-color: #514C9F;
        }
        label{
            padding-left: 10px;
            margin: 0;
        }
    }

    div.DOB{
        margin: 20px 0;
        p{
            font-weight: 400;
            font-size: $size-M;
            line-height: 17px;
            margin-bottom: 10px;
        }
        input{
            height: 48px;
            width: 100%;

            padding: 10px;

            background: #ECEBFF;
            border-radius: 4px;
            border: none;
        }
    }

    // button.submit{
    //     width: 100%;
    //     height: 48px;
    //     background: #514C9F;
    //     margin-top: 20px;
    //     color: #fff;
    //     font-weight: 700;
    //     font-size: 14px;
    //     line-height: 17px;
    //     border-radius: 8px;
    //     border: none;
    // }
}
