$size-XL : 24.75px;
$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main.verifyOtp{
    img#logo{
        aspect-ratio: 4/1;
        height: 40px;
        margin-top: 10px;
        margin-bottom: 32px;
    }

    h2{
        margin-bottom: 16px;
    }
    p{
        margin-bottom: 10px;
        font-size: $size-M;
    }
    div.number-group{
        // padding-left: 25px;
        // padding-right: 25px;
        // padding: 0 16px;
        div.viewAndChangeNumber{
            display: flex;
            justify-content: space-between;
            padding: 5px 12px;
            padding-bottom: 10px;
            margin-bottom: 10px;

            div.phoneNumber{
                font-weight: 400;
                font-size: $size-M;
                line-height: 17px;
            }
            a.changeNumber{
                font-weight: 600;
                font-size: $size-M;
                line-height: 17px;
                text-decoration-line: underline;
                cursor: pointer;
            }

        }

        div.otpInputGroup{
            display: flex;
            justify-content: space-around;

            input.otpDigit{
            padding: 10px;
            text-align: center;

            appearance: textfield;

            width: 75px;
            height: 48px;

            background: #ECEBFF;
            border: none;
            border-radius: 4px;


            }
        }
        
        p#error{
            font-size: $size-S;
            font-weight: 400;
            color: red;
            margin: 10px 0 5px 0;
            display: none;
        }
    }
    
}