$size-XL : 24.75px;
$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main.arthBankSelection{
    padding: 10px;
    width: 100%;
    position: relative;

    h3{
        margin: 1rem 0;
    }

    div.search{
        display: flex;
        align-items: center;
        padding-left: 8px;
        .searchIcon {
            position: absolute;
            // left: 2rem;
            margin: 1rem;
            margin-top: 3rem;
            color: rgba(0, 0, 0, 0.4);;
        }
        input#searchBox{
            padding: 10px;
            padding-left: 3rem;
            margin-top: 2rem;
            width: 100%;
            height: 48px;

            font-weight: 400;
            font-size: $size-M;
            line-height: 17px;

            // text-transform: uppercase;
    
            background: #ECEBFF;
    
            border: none;
            border-radius: 4px;
        }
    }

    div.list{
        margin-top: 1rem;
        height: calc(100vh - 174px);
        overflow-y: scroll;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        div.bank{
            display: flex;
            align-items: center;

            z-index: 3;
            margin-top: 0.5rem;
            
            width: 100%;
            div.bankIcon{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                width: 48px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                z-index: 2;
                img{
                    width: 17.19px;
                    height: 19px;
                }
            }
            div.questionMark{
                display: flex;
                align-items: center;
                justify-content: center;

                height: 48px;
                width: 48px;

                border-radius: 8px;
                background: #FFF2E7;

                font-size: 24px;
                font-weight: 700;
                color: #DB4E4E;
            }
            div.bankName{
                width: calc(100% - 48px);
                padding: 10px;
                display: flex;
                flex-direction: column;
                // align-items: center;
                z-index: 2;
            
                span.continueLink{
                    font-weight: 700;
                    text-decoration: underline;
                }
                span.continueLink:hover{
                    cursor: pointer;
                }
            }
        }
        div.bank:first-child{
            margin-top: 0.5rem;
        }
        div.noBankFound{
            margin-top: 1.5rem;
        }

    }
    div.list::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
}


div.loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 7rem;
    color: #514c9f;
    font-size: $size-L;
    img.spinner{
        width: 30%;
        margin-bottom: 2rem;
    }
}