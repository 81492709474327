main.qrDataPage{

    div.doctorDetails{
        width: 100%;
        padding: 0px 10px;
        margin-bottom: 32px;

        div.doctorDetailDiv{
            display: flex;
            align-items: center;
            // justify-content: space-between;
            margin-bottom: 6px;

            span.title{
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }

    div.qrDownloadOptions{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        div.downloadOption{
            width: 45%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 5px 1px;
            border-radius: 8px;
            padding: 10px;

            div.imgContainer{
                height: 180px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
                img{
                    object-fit: contain;
                    max-width: 60%;
                }

            }
            button{
                width: 100%;
                border: none;
                padding: 8px 10px;
                border-radius: 4px;
                background: #514C9F;
                color: whitesmoke;
                font-weight: 600;
            }

        }
    }
}