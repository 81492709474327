main.addPatient{
    padding: 20px 10px;
    background: white;
    min-height: 100vh;
    div.header{
        display: flex;
        align-items: center;
        gap: 16px;
        // padding: 16px 10px;

        .icon{
            font-size: 22px;
            cursor: pointer;
        }
        span{
            font-size: 16px;
            font-weight: 600;
        }
    }
    hr{
        height: 1.5px;
        color: rgba($color: #000000, $alpha: 0.4);
    }

    div.inputGroup{
        p.group-title{
            margin: 16px 0 0 0;
        }
        input.group-input, select{
            width: 100%;
            border: none;
            background: #ECEBFF;
            padding: 16px 10px;
            font-size: 15.5px;
            border-radius: 4px;
            margin: 12px 0 0 0;
            appearance: textfield;
        }
    }

    p#successMsg{
        display: none;
        font-size: 16px;
        font-weight: 600;
        color: green;
        margin-top: 16px;
    }

    button.submit{
        background: #514C9F;
        color: white;
        margin: 26px 0;
    }
}