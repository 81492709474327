$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main.addressDetails{
    padding: 0 10px;
    // margin-bottom: 2rem;
    h3{
        font-weight: 700;
        font-size: $size-L;
        line-height: 19px;
        padding: 24px 0 20px 0;
    }
    p.sectionHead{
        font-size: 18px;
        margin-bottom: 1rem;
    }
    div.verifyPrompt{
        margin-top: 0;
    }
    div:not(.verifyPrompt){
        margin-bottom: 16px;
        p{
            padding: 4px;
            margin: 2px 0 10px 0;

            font-weight: 400;
            font-size: $size-M;
            line-height: 17px;
        }
        input,select{
            width: 100%;
            height: 48px;

            padding: 10px;

            font-size: $size-M;
            font-weight: 400;
            
            background: #ECEBFF;
            border-radius: 4px;
            border: none;
        }

        // div.pincode{
        //     input{
        //         appearance: textfield;
        //     }
        // }
    }
    // button.submit{
    //     width: 100%;
    //     height: 48px;
    //     background: #514C9F;
    //     margin-top: 20px;
    //     color: #fff;
    //     font-weight: 700;
    //     font-size: 14px;
    //     line-height: 17px;
    //     border-radius: 8px;
    //     border: none;
    // }
}

main.addressNotServiceable{
    display: flex;
    flex-direction: column;
    align-items: center;
    header{
        // width: 100%;
        padding: 10px;
        img{
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    img.oopsIcon{
        max-width: 25%;
        margin: 3rem;
        margin-bottom: 2rem;
    }
    p.oopsMsg{
        text-align: center;
        line-height: 150%;
    }
    div.radioWrapper{
        margin-top: 2rem;
        width: 80%;

        p.radioTitle{
            padding: 0 1rem 1rem 0;
        }
        button.submit.lite.faqSubmit{
            margin-top: -12px;
        }
    }
}