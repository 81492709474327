$size-XL : 22px;
$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main#personalDetails{
    p#number-msg{
        font-size: $size-S;
        font-weight: 400;
        color: red;
        // margin: 10px auto;
        // visibility: hidden;
        display: none;
    }

    div.inputGroup{
        // margin-bottom: 8px;
        input{
            margin-bottom: 5px;
        }
        span#panFormat{
            font-size: $size-S;
            color: red;
    
            // visibility: hidden;
            display: none;
        }

    }
    
}