$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main.phoneNumberVerified{
    
    div.centerSection{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 50%;
        transform: translateY(30%);
        
        img{
            width: 30%;
            margin-bottom: 2rem;
            animation: fadeIn 0.8s ease-in 1 forwards;
        }

        @keyframes fadeIn {
            0%{
                padding-top: 1rem;
                opacity: 0;
            }
            100%{
                padding-top: 0rem;
                opacity: 1;
            }
        }
        p.verifiedConfirmation{
            font-weight: 700;
            font-size: $size-L;
            line-height: 24px;

            text-align: center;

            margin-bottom: 1.5rem;

            color: #514C9F;
        }

        p.redirectionMsg{
            font-weight: 400;
            font-size: $size-M;
            line-height: 101.2%;
            
            /* or 14px */
            text-align: center;
            
            color: #000000;
        }

    }

    div.msgBox{
        position: absolute;
        // height: 56px;
        width: max-content;
        bottom: 71px;
        left: 50%;
        transform: translateX(-50%);

        padding: 12px 22px 12px 24px;

        font-weight: 400;
        font-size: $size-M;
        line-height: 18px;
        text-align: center;
        
        color: #514C9F;
        background: #ECEBFF;
        border-radius: 10px;
    }
}