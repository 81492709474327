$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main.arthCurrentEMIExpenses{
    padding: 0px 12px 12px 12px;
    margin-bottom: 2rem;
    h3{
        padding: 24px 0 20px 0;

        font-weight: 700;
        font-size: $size-L;
        line-height: 19px;
    }
    p{
        margin: 10px 0;
    }

    div{
        margin-bottom: 16px;
        p{
            font-weight: 400;
            font-size: $size-M;
            line-height: 17px;

            padding-top: 4px;
            margin-bottom: 10px;
        }
        input{
            padding: 10px;
            
            width: 100%;
            height: 48px;

            font-size: $size-M; // optional

            appearance: textfield;
            
            background: #ECEBFF;
            border-radius: 4px;
            border: none;
        }
    }

    div.timeInJob, div.totalExp{
        div.inputGroup{
            display: flex;
            align-items: baseline;
    
            input, select{
                width: 60px;
                height: 40px;

                text-align: center;

                padding: 10px;
                margin-right: 4px;
                
                background: #ECEBFF;
                border-radius: 4px;
            }
            p{
                margin-right: 4px;
            }
        }
    }


}

