$size-XL : 24.75px;
$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main#homepage{
    display: flex;
    flex-direction: column;
    align-items: center;
    div#head{
        position: sticky;
        top: 0px;
        // outline: 1px solid red;
        padding: 15px;
        text-align: center;
        // margin-top: -10px;
        width: 100%;
        background: white;
        img#logo{
            aspect-ratio: 4/1;
            height: 32px;
            margin-bottom: 0;
        }
    }
    h1{
        margin-bottom: 50px;
    }
    img#hero{
        width: 80%;
    }

    div.number{
        width: 100%;
        padding-top: 16px;

        h2{
            margin: 16px 0;
        }
       
        div.inputGroup{
            input{
                margin-bottom: 6px;
            }
            p#number-msg{
                margin: 0;
                display: none;
                font-size: $size-S;
                color: red;
            }
        }

    }
}