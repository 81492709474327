$filtercolor: rgba(240, 240, 240, 1);
$filtercolor2: rgba(81, 76, 159, 1);
$statscolor: rgba(242, 245, 252, 1);


button {
  cursor: pointer;
}

button.submit {
  width: 100%;
  height: 58px;
  background: #514C9F;
  margin: 20px 0 10px 0;
  color: #fff;
  font-weight: 700;
  font-size: 15.75px;
  line-height: 17px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

button.submit.disabled {
  opacity: 0.6;
  pointer-events: none;
}

input[type="number"] {
  appearance: textfield;
}

div#animation-wrapper {
  position: fixed;
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 4;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.75);

  .multi-ripple {
    width: 8.6rem;
    height: 8.6rem;
    margin: 3rem;

    div {
      position: absolute;
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      border: 0.6rem solid #514C9F;
      filter: brightness(130%);
      animation: 1.5s ripple infinite;

      &:nth-child(2) {
        animation-delay: 0.5s;
      }
    }
  }
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

div#animation-wrapper.animate {
  animation: flash 0.5s linear 1;
}

@keyframes flash {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

div#animation-wrapper.unanimate {
  animation: unflash 0.5s linear 1;
}

@keyframes unflash {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

p.apiError {
  font-size: 13.5px;
  color: red;
  margin: 10px 0 0 2px;
  display: block;
}

p.apiError.hide {
  display: none;
}

.errorAnimate {
  animation: error 0.3s linear;
}

@keyframes error {
  25% {
    transform: translateX(-2px);
  }

  50% {
    transform: translateX(4px);
  }

  75% {
    transform: translateX(-4px);
  }

  100% {
    transform: translateX(2px);
  }
}

.inputBoxError {
  outline: 1px solid red !important;
  background: #ffe8e8 !important;
}

span.fieldError {
  font-size: 13.5px;
  color: red;
  margin: 5px 0 10px 2px;
  display: none;
}


//////////////////////patient index.scss////////////////////////////////
/// 

$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}

// <style>
//   @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
// </style>
// @font-face {
//   src: url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
//   font-family: "DM Sans";
//   size-adjust: 200%;
// }

// .container{
//   max-width: 480px;
//   min-height: 100vh;
//   margin: 0 auto;
//   background-color: #ffff;
//   // border: 1px solid lightgray;
//   -webkit-box-shadow: 0px 0px 4px 2px rgba(148,148,148,1);
//   -moz-box-shadow: 0px 0px 4px 2px rgba(148,148,148,1);
//   box-shadow: 0px 0px 4px 2px rgba(148,148,148,1);
//   // border-radius: 15px;
//   overflow: clip;
// }

input,
select {
  font-size: $size-M;
  outline: none;
}


@media screen and (max-width: 768px) {
  .container {
    box-shadow: none;
  }
}




/////////////////////////////////////Doctor app.scss////////////////////////////

$size-XL : 24.75px;
$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

.clr-purple {
  color: #514C9F;
}

div.inputGroup {
  margin-top: 8px;

  p.group-title {
    font-size: $size-M;
    line-height: 17px;
    margin-bottom: 12px;
  }

  input.group-input,
  select.group-input {
    padding: 10px;
    margin-bottom: 10px;

    appearance: textfield;

    width: 100%;
    height: 48px;

    font-size: $size-M;

    background: #ECEBFF;
    border-radius: 4px;
    border: none;
  }

  span.fieldError {
    font-size: 13.5px;
    color: red;
    margin: 0 0 10px 2px;
    display: none;
  }
}

p.apiError {
  font-size: 13.5px;
  color: red;
  margin: 10px 0 0 2px;
  // visibility: visible;
  display: block;
}

p.apiError.hide {
  // visibility: hidden;
  display: none;
}

button.submit {
  width: 100%;
  height: 58px;
  background: #514C9F;
  margin: 20px 0 10px 0;
  color: #fff;
  font-weight: 700;
  font-size: 15.75px;
  line-height: 17px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

button.submit.lite {
  color: #514C9F;
  background: #ECEBFF;
}

button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

p#number-msg {
  margin: 0;
  display: none;
  font-size: $size-S;
  color: red;
  display: none;
}

.errorAnimate {
  animation: error 0.3s linear;
}

@keyframes error {
  25% {
    transform: translateX(-2px);
  }

  50% {
    transform: translateX(4px);
  }

  75% {
    transform: translateX(-4px);
  }

  100% {
    transform: translateX(2px);
  }
}

.inputBoxError {
  outline: 1px solid red !important;
  background: #ffe8e8 !important;
}

main {
  padding: 0 10px 10px 10px;

  p.heading {
    font-size: $size-XL;
    color: rgba(0, 0, 0, 0.4);
    margin: 16px 0;
  }
}


////////////////////////doctor index/////////////////////////////////


$primary: #ECEBFF;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}


main {
  // position: relative;
  max-width: 480px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #ffff;
  // border: 1px solid lightgray;
  -webkit-box-shadow: 0px 0px 4px 2px rgba(148, 148, 148, 1);
  -moz-box-shadow: 0px 0px 4px 2px rgba(148, 148, 148, 1);
  box-shadow: 0px 0px 4px 2px rgba(148, 148, 148, 1);
  // border-radius: 15px;
  overflow: clip;
}

@media screen and (max-width: 768px) {
  main {
    box-shadow: none;
  }
}


div#animation-wrapper {
  position: fixed;
  // display: flex;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 4;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.6);

  div.multi-ripple {
    width: 8.6rem;
    height: 8.6rem;
    margin: 3rem;

    div.ring {
      position: absolute;
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      border: 0.6rem solid $primary;
      filter: contrast(150%) brightness(200%) saturate(200%);
      animation: 1s ripple infinite;

      &:nth-child(2) {
        animation-delay: 0.3s;
      }
    }
  }
}

div#animation-wrapper.show {
  display: flex;
}

@keyframes flash {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

a {
  text-decoration: none;
  color: inherit;
}








// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }


// div.privacyPolicy{
//   scroll-behavior: smooth;
// }








:root {
  --h1-xsm-font-size: 54px;
  --h1-sm-font-size: 56px;
  --h1-md-font-size: 58px;
  --h1-l-font-size: 62px;
  --h1-xl-font-size: 66px;
  --h1-xxl-font-size: 90px;

  --h-font-family: "sigmundpro-semibold";
  // --h-font-family: 'DM Sans', sans-serif;
}


/* // X-Small devices (portrait phones, less than 576px) */
/* // No media query for `xs` since this is the default in Bootstrap */

/* // Small devices (landscape phones, 576px and up) */

// @font-face {
//   font-family: "FONTSPRING DEMO - Sigmund PRO SemiBold";
//   src: url('../src/assets/Fonts/Fontspring-DEMO-sigmundpro-semibold.otf');
// }

// @font-face {
//   font-family: 'sigmundpro-semibold';
//   src: url('../src/website/assets/Fonts/sigmundpro-semibold-webfont.woff2') format('woff2'),
//        url('../src/website/assets/Fonts/sigmundpro-semibold-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }
div.website-container {
  span {
    font: inherit;
  }

  img {
    max-width: 100%;
  }

  section {
    width: 100%;
    padding: 5vw 0;
  }

  div.container {
    margin: auto;
  }

  p.text-content {
    margin: 1rem 0;
    font-family: 'DM Sans', sans-serif !important;
    font-size: 20px;
  }

  h1 div.percent-wrapper {
    display: inline;
    font-size: 0.5em;
    font-weight: 700;
    font-family: 'DM Sans', sans-serif;
  }


  @media (max-width: 576px) {
    section {
      width: 100%;
      padding: 60px 0;
    }

    div.container {
      max-width: 90%;
    }

    h1 {
      color: rgba(0, 0, 0, 0.80);
      text-align: center;

      /* Mobile H1 */
      font-family: var(--h-font-family);
      font-size: var(--h1-xsm-font-size);
      font-style: normal;
      font-weight: 500;
      line-height: 54px;
      /* 100% */
      letter-spacing: -0.54px;
    }

    h2 {
      color: rgba(0, 0, 0, 0.80);
      text-align: center;

      /* Mobile H2 */
      font-family: var(--h-font-family);
      font-size: calc(0.8 * var(--h1-xsm-font-size));
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      /* 110% */
      letter-spacing: -0.4px;
    }

    h3 {
      color: rgba(0, 0, 0, 0.80);

      /* Desktop H3 */
      font-family: "DM Sans";
      font-size: calc(0.9 * var(--h1-xsm-font-size));
      font-style: normal;
      font-weight: 500;
      line-height: 112.5%;
      /* 112.5% */
    }

    p.text-content {
      color: rgba(0, 0, 0, 0.80);
      // text-align: center;

      /* Mobile B1 regular */
      font-family: 'DM Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }


    h2 span.highlight {
      color: #514C9F;
    }
  }

  @media (min-width: 576px) {
    div.container {
      max-width: 90%;
    }

    h1 {
      color: rgba(0, 0, 0, 0.80);
      text-align: left;

      /* Mobile H1 */
      font-family: var(--h-font-family);
      font-size: var(--h1-sm-font-size);
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      /* 100% */
      letter-spacing: -0.54px;
    }

    h2 {
      color: #263238;
      text-align: center;

      /* Desktop H2 */
      font-family: var(--h-font-family);
      font-size: calc(0.8 * var(--h1-sm-font-size));
      font-style: normal;
      font-weight: 500;
      line-height: 125%;
      /* 125% */
    }

    h3 {
      color: rgba(0, 0, 0, 0.80);

      /* Desktop H3 */
      font-family: 'DM Sans', sans-serif;
      font-size: calc(0.8 * var(--h1-sm-font-size));
      font-style: normal;
      font-weight: 500;
      line-height: 112.5%;
      /* 112.5% */
    }

    p.text-content {
      color: rgba(0, 0, 0, 0.80);
      text-align: left;

      /* Mobile B1 regular */
      font-family: 'DM Sans', sans-serif;
      // font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }

    p.text-content.center {
      text-align: center;
    }
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    div.container {
      max-width: 90%;
    }

    h1 {
      color: #263238;
      font-size: var(--h1-md-font-size);
      line-height: 110%;
      /* 100% */
      letter-spacing: normal;
    }

    h2,
    h3 {
      font-size: calc(0.8 * var(--h1-md-font-size));
    }

    p.text-content {
      color: rgba(0, 0, 0, 0.80);
      text-align: left;

      /* Desktop B1 Regular */
      font-family: 'DM Sans', sans-serif;
      // font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 145.455%;
      /* 145.455% */
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    div.container {
      max-width: 80%;
    }

    h1 {
      font-size: var(--h1-l-font-size);
      line-height: 100%;
      /* 100% */
    }

    h2,
    h3 {
      font-size: calc(0.8 * var(--h1-l-font-size));
    }

    // p.text-content{
    //   font-size: 16px;
    // }
  }

  /* // X-Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    div.container {
      max-width: 70%;
    }

    h1 {
      font-size: var(--h1-xl-font-size);
    }

    h2,
    h3 {
      font-size: calc(0.8 * var(--h1-xl-font-size));
    }

  }

  /* // XX-Large devices (larger desktops, 1400px and up) */
  @media (min-width: 1400px) {
    div.container {
      max-width: 70%;
    }

    h1 {
      font-size: var(--h1-xxl-font-size);
    }

    h2,
    h3 {
      font-size: calc(0.8 * var(--h1-xxl-font-size));
    }

    // p.text-content{
    //   font-size: 16px;
    // }
  }


  .custom-container {
    margin: 1rem auto 0 auto;
    padding-top: 2rem;
    max-width: 90%;

    h2,
    h5 {
      font-weight: 700;
      text-align: left;
      margin-bottom: 1rem;
    }

    h5.policy-heading {
      text-decoration: underline;
      text-align: left;
      font-size: 18px;
    }

    p,
    ul,
    li {
      margin: 1rem;
      font-size: 16px;
    }

    section {
      // position: relative;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 4rem;
      padding: 0;
      justify-content: space-between;


      div.left-section {
        position: sticky;
        top: 5rem;
        left: 0;
        width: 25%;
        z-index: 0;
        height: 80vh;
        overflow-y: scroll;

        ul.outline-point {
          li {
            list-style: none;
            padding: 4px;
            margin: 12px 0;
            font-size: 0.9em;
            color: rgba(0, 0, 0, 0.75);
            transition: all 0.2s ease;
            cursor: pointer;

            a {
              text-decoration: none;
              color: inherit;
            }

            &:hover {
              color: #514C9F;
              background: #ECEBFF;
            }
          }
        }
      }

      div.right-section {
        width: 70%;

        h5.policy-heading {
          margin: 3rem 0 1rem 0;
        }

        ul {
          margin-left: 1rem;
        }
      }
    }

  }

  @media screen and (max-width: 768px) {
    .custom-container {
      section {
        div.left-section {
          display: none;
        }

        div.right-section {
          width: auto;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ECEBFF;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(82, 76, 159, 0.8);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #514C9F;
}

input.dynamicFetching:disabled::placeholder {
  font-style: italic;
}

div.bottomPopOverModal {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;

  div.popUpCard-custom {
    bottom: -100%;
    transition: all 0.3s ease-in-out;
  }

  &.open {
    opacity: 1;
    visibility: visible;

    div.popUpCard-custom {
      bottom: 0;
    }
  }
}

.screen-width-max {
  background-color: #fff;
  box-shadow: 0 0 4px 2px #949494;
  margin: 0 auto;
  max-width: 480px;
  min-height: 100vh;
  overflow: clip;
}

.share-btn {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  line-height: 2;
  margin-top: -16px;
  font-weight: 700;



}

.header-scouts {
  // text-align: center;
  padding: 15px;
  border-bottom: 5px solid #f2f2f2;

  img {
    width: 150px;
  }
}

.scout-body {
  padding: 20px;
  margin-top: 10px;
}

.purple-bg-box {
  cursor: pointer;
  background: #ECEBFF;
  color: #514C9F;
  font-weight: 500 !important;
  padding: 15px;
  border-radius: 5px;
  font-size: 26px;
  margin-top: 30px;
}

.yellow-bg-box {
  cursor: pointer;
  background: #FFF7E5;
  color: #E09900;
  font-weight: 500 !important;
  padding: 15px;
  border-radius: 5px;
  font-size: 26px;
  margin-top: 10px;
}

.scout-login-body {
  padding: 20px;
  background: #F8F8FF;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  h1 {
    font-weight: 500 !important;
    color: #514C9F;
    ;
  }

  p {
    font-size: 14px;
  }
}

.scout-second-div {
  padding: 20px;

  h4 {
    font-weight: 500 !important;
    color: #514C9F;
  }

  .input-number {
    background-color: #f8f8fe;
    padding: 12px;
    border: none;
    outline: none;
    border-radius: 5px;
    // font-size: 14px;
  }
}

.carepay-button-purple {
  margin-top: 20px;
  color: #fff;
  background: #514C9F;
  padding: 12px;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 100%;
  font-weight: 700;
}

.otp-enter {
  input {
    background-color: #f8f8fe;
    padding: 20px;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 25% !important;

  }
}

.carepay-button-purple-disable {
  margin-top: 20px;
  color: #fff;
  background: #908DC1;
  padding: 12px;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 100%;
  font-weight: 700;
  // cursor:not-allowed;

}

input[type="number"] {
  -moz-appearance: textfield;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mobile-menu {
  z-index: 1;
  width: 100%;

}

.menu-icon {
  display: inline-block;
  cursor: pointer;
  padding: 15px;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.menu-items {
  // position: absolute;
  top: 30px;
  right: 0;
  min-height: 80vh;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  display: none;


}

.menu-items.open {
  display: block;
}

.menu-tab {
  font-size: 20px;
  padding: 10px;
}

.txn-card {
  border: 1px solid #0000001A;
  min-height: 190px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px;
}

.approved-div {
  color: rgb(255, 255, 255);
  background: rgb(0, 170, 119);
  margin-left: -10px;
  margin-bottom: -10px;
  margin-right: -10px;
  margin-top: 10px;
  border-bottom-left-radius: 10px;
  padding: 6px 13px;
  border-bottom-right-radius: 10px;
  font-size: 14px;
}

.carepay-button-card {
  font-size: 12px;
  margin-top: 20px;
  color: #fff;
  background: #514C9F;
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 100%;
}

.over-view-component {
  .screen-width-max {
    background-color: #fff;
    box-shadow: 0 0 4px 2px #949494;
    margin: 0 auto;
    max-width: 480px;
    min-height: 100vh;
    overflow: none;
    position: relative;
    z-index: 1;
  }

  .bg-grey {
    background-color: rgb(234, 224, 224) !important;
  }

  h5,
  h3,
  h4 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .dashboard {
    position: sticky;
    top: 0;

    .filters {
      padding-left: 15px;
      display: flex;
      padding-top: 10px;
      padding-bottom: 10px;
      overflow-x: auto;
      background-color: white;

      .box {
        background-color: $filtercolor;
        border-radius: 4px;
        padding: 11px 9px 3px 17px;
        margin-right: 13px;
        display: flex;
        cursor: pointer;

        h5 {
          margin: 0px;
          font-size: 12px;
          font-weight: 400;
          color: #000;
          white-space: nowrap;
        }

        span svg {
          color: rgba(0, 0, 0, 0.6);
          width: 17px;
          height: 17px;
          margin-top: -10px;
        }

        &.active {
          background-color: $filtercolor2 !important;

          h5 {
            color: #fff !important;
          }

          span svg {
            color: #fff !important;
          }
        }
      }
    }
  }

  .trends {

    margin-top: 10px;
    background-color: white;

    .cols {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .columns {
      width: 97%;
      display: flex;
      background-color: rgb(242, 245, 252);
      border-radius: 4px;
      padding: 10px;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;


      .Lefttext {
        h3 {
          font-size: 24px;
          font-weight: 400;
        }

        h4 {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .Righttext {
        h5 {
          margin-top: 5px;
          color: rgba(0, 0, 0, 0.4);
          font-size: 14px;
          font-weight: 400;
        }
      }

    }

    .columns-new {
      width: 97%;
      // display: flex;
      background-color: rgb(242, 245, 252);
      border-radius: 4px;
      padding: 10px;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      font-weight: 400;
    }

    .box-new {
      h3 {
        font-size: 24px !important;
        font-weight: 400 !important;

      }

      h4 {
        font-size: 14px !important;
        font-weight: 400 !important;

      }

    }

    .box {
      background-color: rgba(242, 245, 252, 1);
      border-radius: 4px;
      margin-left: auto;
      margin-right: auto;
      width: 97%;
      margin-top: 10px;

      .topbox {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 10px;
        display: flex;
        justify-content: space-between;

        h3 {
          font-size: 14px;
          font-weight: 400;
        }

        h5 {
          font-size: 24px;
          font-weight: 400;
        }
      }

      .bottombox {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        background-color: rgba(81, 76, 159, 1);

        h3 {
          font-size: 14px;
          font-weight: 400;
          color: white;
        }

        h5 {
          font-size: 14px;
          font-weight: 700;
          color: white;
        }
      }
    }

    .bargraph {
      margin-top: 15px;

      h5 {
        span svg {
          color: rgba(81, 76, 159, 1);
        }

        font-size: 18px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }

      .tabsrow {
        display: flex;
        overflow-y: auto;
        margin-top: 10px;
        margin-bottom: 5px;

        .tabs {
          padding: 10px;
          cursor: pointer;

          h5 {
            white-space: nowrap;
            font-size: 12px;
            font-weight: 400;
          }

          &.showtabs {
            border-bottom: 2px solid rgba(144, 141, 193, 1);

            h5 {
              font-size: 12px;
              font-weight: 700;
              color: rgba(81, 76, 159, 1);
            }
          }
        }
      }

      .buttonswitch {
        .btnswitch {
          margin-top: 10px;
          padding: 4px 6px;
          background-color: rgba(236, 235, 255, 1);
          display: inline-flex;
          border-radius: 4px;

          .h5tab {
            cursor: pointer;
            // margin-top: 4px;
            color: rgba(144, 141, 193, 1);
            font-size: 12px;
            font-weight: 700;
            padding: 5px;

            &.active {
              border-radius: 4px;
              background-color: white;
              font-weight: 400 !important;
              color: rgba(81, 76, 159, 1) !important;

            }
          }
        }
      }

      .barchart {
        .text {
          border-radius: 4px;
          background-color: rgba(236, 235, 255, 1);
          padding: 10px;
          margin-bottom: 30px;

          h5 {
            font-size: 12px;
            color: rgba(71, 67, 141, 1);
            font-weight: 400;

            span {
              font-weight: 700 !important;
            }
          }
        }
      }


    }

  }

  .filter-dropdown {
    width: 480px;
    background-color: #fff;
    text-align: -webkit-center;
    left: auto;
    right: auto;
    // position: sticky;
    // top: 100%;
    position: fixed;
    // position: absolute;
    width: -webkit-fill-available;
    bottom: 0;
    z-index: 10;
    max-width: 480px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 15px;


    .filterscout {
      .filterbox {
        display: flex;
        justify-content: space-between;

        .closeicon {
          cursor: pointer;
        }

        h5 {
          font-size: 16px;
          font-weight: 700;
          color: #000;
        }

        span svg {
          color: rgba(0, 0, 0, 0.6);
          width: 23px;
          height: 23px;
        }
      }

      .checkboxes {
        padding: 8px;
        max-height: 300px;
        overflow-y: auto;

        .checkboxgrp {
          display: flex;
          margin-top: 15px;

          .checkboxinput {
            margin-right: 10px;
            width: 18px;
            height: 18px;
          }

          .checkboxinputlabel {
            font-size: 14px;
            font-weight: 400;
            margin-top: 2px;
          }
        }
      }

      .inputsamount {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 5px;

        .inputboxes {
          border-radius: 6px;
          // border: 1px solid black;
          background-color: rgba(236, 235, 255, 1);

          .iconruppee {
            padding-left: 20px;
            font-size: 16px;
          }

          .amount {
            background-color: rgba(236, 235, 255, 1);
            padding: 15px;
            width: 70%;
            border: none;
            font-size: 16px;

            &:focus-visible {
              outline: none !important;
            }
          }
        }

        h5 {
          margin-bottom: 5px;
          margin-top: 6px;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
        }
      }

      .filter-btn {
        margin-top: 30px;

        .apply-filter {
          width: 98%;
          border-radius: 6px;
          background-color: $filtercolor2;
          border: none;
          color: #fff;
          padding: 15px;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 360px) {
    .trends {
      .cols {
        width: 100% !important;
      }
    }
  }
}

.background-blur {
  position: fixed;
  background: rgb(0 0 0 / 49%);
  z-index: 1;
  top: 0;
  left: auto;
  bottom: 0;
  right: auto;
  // color: $bluecolor;
  overflow: auto;
  text-align: -webkit-center;
  max-width: 480px;
  width: 480px;
}

.text-center {
  text-align: center;
}

.text-danger {
  color: #FF0000;
  font-size: 12px;
  margin-top: 10px;
}

.three-dots {
  float: right;
  position: relative;
  right: 2px;
  /* text-align: right; */
  width: 21px;

}

.Scout-Details-form {
  &.screen-width-max {
    background-color: #fff;
    box-shadow: 0 0 4px 2px #949494;
    margin: 0 auto;
    max-width: 480px;
    min-height: 100vh;
    overflow: clip;


    .header {
      background-color: white;
      box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.05);
      padding: 15px 10px;
      position: sticky;
      top: 0;
      z-index: 5;

      span svg {
        cursor: pointer;
        width: 38px;
        height: 22px;
        margin-right: 10px;
      }

    }

    .Merchantform {
      margin-top: 15px;
      padding: 15px 10px;

      .heading {
        display: flex;
        justify-content: space-between;

        h3 {
          color: $filtercolor2;
          font-size: 32px;
          font-weight: 500;
        }

        .square {
          background-color: #fae1cd;
          border-radius: 8px;
          width: 36px;
          height: 36px;
          text-align: center;

          img {
            margin-top: 9px;
          }
        }
      }

      .detailsform {
        margin-top: 30px;

        h4 {
          font-size: 18px;
          font-weight: 700;
        }

        .inputtags {
          margin-top: 15px;
          padding-left: 10px;
          padding-right: 10px;

          .inputsgrps {
            margin-top: 20px;

            .input-label {
              color: #000;
              font-size: 14px;
              font-weight: 400;
              display: block;
            }

            .input-tag {
              padding: 11px 10px;
              margin-top: 8px;
              width: 100%;
              border: none;
              border-radius: 4px;
              background-color: #f8f8ff;
              color: $filtercolor2 !important;

              &:focus {
                outline: none;
                box-shadow: none !important;
                border: 1px solid $filtercolor2 !important;
              }
            }

            .form-select {
              --bs-form-select-bg-img: url('./Scouts/Forms/images/dropdown-arrow.png');
              background-size: 15px;

            }

            .input-tag[type="date"] {
              background-image: url('./Scouts/Forms/images/Frame 113909.png');
              background-repeat: no-repeat;
              background-size: 20px;
              margin-right: 10px;
              background-position: 97%;
            }

            .input-tag[type="date"]::-webkit-calendar-picker-indicator {
              background: none !important;
            }

            .input-tag[type="file"] {
              padding: 9px 10px !important;
              border-radius: 4px;
              border: none !important;
              background-color: #f8f8ff;

              &:focus {
                box-shadow: none !important;
                border: none !important;
              }
            }

            .input-file-label {
              display: block;
              position: relative;
              margin-top: -42px;
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.4);

              &:focus {
                color: $filtercolor2 !important;
                box-shadow: none !important;
                border: 1px solid $filtercolor2 !important;
              }

              span {
                color: $filtercolor2;

                svg {
                  width: 20px;
                  height: 20px;
                  margin-right: 3px;
                  margin-bottom: -6px;
                }
              }
            }

            .checkbox-grp {
              display: flex;

              .input-checkbox {
                margin-right: 8px;
                width: 16px;
                height: 16px;
              }

              .input-label {
                a {
                  text-decoration: underline;
                  font-weight: 700;
                  cursor: pointer;
                }
              }
            }

            .error-msg {
              color: red;
              margin-top: 10px;
              font-size: 10px;
              font-weight: 400;
            }
          }
        }

        .btn {
          margin-top: 20px;
          width: 100%;
          color: white;
          cursor: pointer;
          padding: 15px;
          font-weight: 700;
          font-size: 14px;
          border-radius: 4px;
          border: none;
          background-color: $filtercolor2;
        }
      }
    }

  }
}

.Under-review-form {
  &.screen-width-max {
    background-color: #fff;
    box-shadow: 0 0 4px 2px #949494;
    margin: 0 auto;
    max-width: 480px;
    min-height: 100vh;
    overflow: clip;
  }

  .header {
    text-align: center;
    background-color: white;
    box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.05);
    padding: 15px 10px;
    position: sticky;
    top: 0;
    z-index: 5;

  }

  .under-review-text {
    margin-top: 50px;
    padding: 20px;
    text-align: center;

    h5 {
      font-size: 14px;
      font-weight: 400;
      margin-top: 50px;
      color: rgba(0, 0, 0, 0.8);
    }

    h6 {
      margin-top: 10px;
      color: rgba(0, 0, 0, 0.4);
      font-size: 14px;
      font-weight: 700;
    }

    .btn {
      margin-top: 30px;
      width: 100%;
      color: white;
      cursor: pointer;
      padding: 15px;
      font-weight: 700;
      font-size: 14px;
      border-radius: 4px;
      border: none;
      background-color: $filtercolor2;
    }
  }

  .faq-section {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: rgba(236, 235, 255, 1);
    padding: 20px;
    margin-top: 10px;

    h5 {
      color: rgba(144, 141, 193, 1);
      font-size: 24px;
      font-weight: 700;
    }

    .FAQs {
      margin-top: 10px;

      .accords {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        h5 {
          color: rgba(0, 0, 0, 0.8);
          font-size: 14px;
          font-weight: 400;
        }

        .heading {
          padding: 13px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;

          .imgs {
            text-align: right;

            img {
              transform: rotate(0deg);
              transition: transform .2s ease-in;
            }

            &.animation-rotate {
              img {
                transform: rotate(180deg);
              }
            }
          }
        }


        .bottomtext {
          overflow: hidden;
          max-height: 0px;
          transition: max-height .2s ease-in-out;

          &.slidin-animation {
            max-height: 300px !important;
            padding: 10px;
            // animation: slidin-animation 1s ease; 
          }

          &.slideout-aniamtion {
            max-height: 0px !important;
            // animation: slidout-animation 1s ease; 
          }


        }
      }
    }

    .allfaqlink {
      margin-top: 15px;
      text-align: center;

      .allfaq {
        color: $filtercolor2;
        text-decoration: underline;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .contact-section {
    background-color: #EBFEED;
    padding: 35px 15px 0px 15px;

    h5 {
      text-align: center;
      line-height: 23px;
      font-size: 14px;
      font-weight: 400;

    }

    h3 {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      color: rgb(20, 149, 64);
    }

    .sub-section {
      padding: 20px;

      .box {
        border-radius: 24px;
        padding: 8px 24px;
        display: flex;
        background-color: white;
        border: none;
        margin: 15px auto;

        img {
          margin-right: 10px;
        }

        h5 {
          margin-top: -2px;
          font-size: 14px;
          font-weight: 700;
          color: #149540;
        }
      }
    }
  }

  .feedback-section {
    padding: 35px 15px 20px 15px;

    h5 {
      text-align: center;
      line-height: 23px;
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      font-weight: 400;

    }

    h3 {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      color: rgba(241, 161, 100, 1);
    }

    .sub-section {
      .box {
        border-radius: 24px;
        padding: 8px 20px;
        display: flex;
        background-color: rgba(241, 161, 100, 1);
        border: none;
        margin: 15px auto;

        img {
          margin-right: 10px;
          margin-top: 3px;
        }

        h5 {
          font-size: 14px;
          font-weight: 700;
          color: white;
        }
      }
    }
  }
}

@keyframes slidin-animation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slidout-animation {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }

}

.search-bank {
  input[type="search"] {
    border: none;
    background: ECEBFF;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
  }

  input[type="search"]::placeholder {
    color: #bbb;
  }

  button[type="submit"] {
    text-indent: -999px;
    overflow: hidden;
    width: 40px;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    cursor: pointer;
    opacity: 0.7;
  }

  button[type="submit"]:hover {
    opacity: 1;
  }

  button[type="submit"]:focus,
  input[type="search"]:focus {
    box-shadow: 0 0 3px 0 #1183d6;
    border-color: #1183d6;
    outline: none;
  }

  form.nosubmit {
    border: none;
    padding: 0;
  }

  input.nosubmit {
    border: 1px solid #555;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: #ECEBFF;
    background-image: url("./patient/assets/Search.svg");
    background-size: 21px;
    background-repeat: no-repeat;
    background-position: 11px;
    border: none;
    border-radius: 5px;

  }


}

.logo-grid {
  width: 70%;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.bank-logo {
  width: 95%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.radio-btn {
  div.gender {
    font-weight: 400;
    font-size: $size-M;
    line-height: 17px;
    padding: 4px 0px 0px 0px;

    p {
      padding-bottom: 16px;
    }

    div.radioOption {
      display: flex;
      align-items: center;
      padding-bottom: 15px;
    }

    div.radioOption:last-child {
      padding-bottom: 0;
    }

    input {
      height: 24px;
      width: 24px;
      border: 2px solid #5E5E5E;
      accent-color: #514C9F;
    }

    label {
      padding-left: 10px;
      margin: 0;
    }
  }
}

.input-scout {

  background-color: rgb(236, 235, 255);
  padding: 12px;
  border: none;
  outline: none;
  border-radius: 5px;
  // font-size: 14px;

}

.scout-search {
  border: 1.5px solid #999999 !important;
  padding: 10px !important;
  border-radius: 5px !important;
  padding-left: 35px !important;
  font-weight: 700 !important;
  background: url('./Scouts/imagesscouts/Vector\ \(3\).svg') no-repeat !important;
  background-position: 10px center !important;
  background-size: 20px 20px !important;

  ::placeholder {
    color: #999999 !important;
  }
}

.scout-loan-div-blue {
  padding: 10px;
  margin-top: 20px;
  background-color: #F2F5FC;
  border-radius: 5px;
}

.bankinput-checkbox {
  .round {
    position: relative;
  }

  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 25px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;

  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 6px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked+label {
    background-color: #514C9F;
    border-color: #8f8dbd;
  }

  .round input[type="checkbox"]:checked+label:after {
    opacity: 1;
  }





}

.otp-loader {
  .loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 28px;
    height: 28px;
    animation: spin 2s linear infinite;

  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.formbold-file-input input {
  opacity: 0;
  position: absolute;
  width: 100%;
  // height: 100%;
  cursor: pointer;
}

.formbold-file-input label {
  position: relative;
  border: 1px dashed #e0e0e0;
  border-radius: 6px;
  // min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 25px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  background-color: #ECEBFF;
}

.formbold-file-input label:hover {
  background-color: #f9f9f9;
}

.formbold-drop-file {
  display: block;
  font-weight: 600;
  color: #07074d;
  font-size: 20px;
  margin-bottom: 8px;
}

.formbold-or {
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  display: block;
  margin-bottom: 8px;
}

.formbold-browse {
  font-weight: 500;
  font-size: 14px;
  color: #8e8d98;
  display: flex;
  padding: 8px 28px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.formbold-browse:hover {
  // background-color: #514C9F;
  // color: white;
}

.ecom {
  .ecom-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    position: sticky;
    width: 100%;
    top: 0;
    background: #fff;
    z-index: 999;
    border-bottom: 5px solid #f2f2f2;
  }

  .logo-container {
    width: 40%;
  }

  .location-container {
    // width: 20%;
    display: flex;
    justify-content: center;
  }

  .location-box {
    background-color: #ecebff;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    gap: 5px;
  }

  .city-name {
    margin-top: -2px;
  }

  .search-container {
    display: flex;
    align-items: center;
    background: #ecebff;
    border-radius: 24px;
    padding: 10px;
    width: 95%;
    // max-width: 400px;
    margin: 0 auto;
  }

  .search-icon {
    color: #6c6c6c;
    font-size: 20px;
    margin-right: 8px;
  }


  .search-input {
    width: 95%;
    border-radius: 24px;
    padding: 4px;
    outline: none;
    background: #ECEBFF;
    border: none;
  }

  .treatments-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 15px;
    max-width: 600px;
    margin: 0 auto;
  }

  .treatment-card {
    // background: #ecebff;
    // padding: 8px;
    border-radius: 10px;
    text-align: center;
    font-size: 16px;
    // font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease;
    position: relative;
  }

  .treatment-card:hover {
    background: #d4d3ff;
  }

  .see-more-btn {
    display: block;
    background: #fff;
    color: #514C9F;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    margin: 20px auto;
    cursor: pointer;
    text-align: center;
    transition: background 0.3s ease;
    border: 1px solid #514C9F66;
  }

  .see-more-btn:hover {
    background: #514C9F;
    color: #fff;
  }

  .hero-section {
    text-align: center;
    margin-top: 30px;

  }

  // .card-doc-bg{
  //   background: linear-gradient(to right, #514C9F 0%, #999999 100%);
  // }

  .card-doc-bg {
    background: linear-gradient(to right, #514C9F 0%, transparent 100%);
    padding: 5px;
    color: #fff;
    position: absolute;
    top: 100px;
    // left: 16.5px;
  }

  .view-btn {
    display: block;
    background: #fff;
    color: #514C9F;
    padding: 10px 16px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    transition: background 0.3s ease;
    border: none;
    font-weight: bold;

  }
}


.purple-text {
  color: #514C9F;
}

.select-box {
  background: #ECEBFF;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 5px;

}

.lable-ecom {
  margin-top: 10px;
  margin-left: 3px;
  margin-bottom: 5px;
}

.verify-btn {
  background: #ECEBFF;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
}

div.otpInputGroup {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  input.otpDigit {
    padding: 10px;
    text-align: center;

    appearance: textfield;

    width: 75px;
    height: 48px;

    background: #ECEBFF;
    border: none;
    border-radius: 4px;


  }
}

.MuiStepLabel-label {
  line-height: 10px !important;
}

.gender-tab {
  border: 1px solid #000000;
  text-align: center;
  padding: 10px;
}

.bureau-approved {
  padding: 20px;
  font-family: Arial, sans-serif;

  .congratulations {
    text-align: center;
    margin-top: 10px;
    color: #4c9f68;
  }

  .loan-approved {
    background: #ebfeed;
    border-radius: 10px;
    margin-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    color: #4c9f68;

    .approved-text {
      padding-top: 10px;
      font-size: 18px;
    }

    .approved-amount {
      font-size: 24px;
      background-image: linear-gradient(to right, rgb(76, 159, 104), rgb(77, 210, 122));
      color: #ffffff;
      padding: 14px;
      margin-top: 10px;
      border-radius: 10px;
    }
  }

  .select-emi {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 25px;
    font-size: 16px;
  }

  .emi-plan {
    position: relative;
    margin-bottom: 10px;
  }

  // .recommended-badge {
  //     position: absolute;
  //     background-image: linear-gradient(to right, rgb(76, 159, 104), rgb(77, 210, 122));
  //     font-weight: bold;
  //     color: #fff;
  //     top: -18px;
  //     left: 0;
  //     right: 0;
  //     border-top-right-radius: 10px;
  //     border-top-left-radius: 10px;
  //     padding: 4px;
  // }
  .recommend-class {
    border-radius: 10px;
    padding: 6px 3px 3px 3px;
    position: relative;
    margin-bottom: 10px;
    background: linear-gradient(to right, rgb(76, 159, 104), rgb(77, 210, 122));

  }

  .emi-card {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    padding: 10px;
    border-radius: 10px;
 
    cursor: pointer;
    transition: all 0.3s ease;

    &.recommended-border {
      border: none;
      margin-top: 10px;
      // border-top-right-radius: 0;
      // border-top-left-radius: 0;
      padding-top: 20px;
    }

    &:hover {
      background-color: #f9f9f9;
    }
  }

  .emi-content {
    display: flex;
    align-items: center;

    .radio-container {
      width: 20%;
      text-align: center;

      input[type="radio"] {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .emi-details {
      width: 80%;
      display: flex;
      flex-direction: column;

      .emi-row {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #e6e6e6;

        h3 {
          margin: 5px 0;
          font-weight: bold;
        }
      }
    }
  }

  .see-more-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    background: #ECEBFF;
    color: #514C9F;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;

    svg {
      font-size: 20px;
    }
  }



}