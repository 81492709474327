$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main.enterOTP{
    padding: 0 12px;


    h3.enterOTPHeading{
        font-size: $size-L;
        font-weight: 700;
        padding-bottom: 20px;
        line-height: 24px;
        // border: 1px solid red;
    }

    div.number-group{
        padding-left: 25px;
        padding-right: 25px;
        div.viewAndChangeNumber{
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
            padding-bottom: 10px;
            margin-bottom: 10px;

            div.phoneNumber{
                font-weight: 400;
                font-size: $size-M;
                line-height: 17px;
            }
            a.changeNumber{
                font-weight: 600;
                font-size: $size-M;
                line-height: 17px;
                text-decoration-line: underline;
                cursor: pointer;
            }

        }

        div.otpInputGroup{
            display: flex;
            justify-content: space-between;

            input.otpDigit{
            padding: 10px;
            text-align: center;

            appearance: textfield;

            width: 75px;
            height: 48px;

            background: #ECEBFF;
            border: none;
            border-radius: 4px;


            }
        }
        
        p#error{
            font-size: $size-S;
            font-weight: 400;
            color: red;
            margin: 10px 0 5px 0;
            display: none;
        }
    }

    // button.submit{
    //     width: 100%;
    //     height: 48px;
    //     background: #514C9F;
    //     margin-top: 20px;
    //     color: #fff;
    //     font-weight: 700;
    //     font-size: 14px;
    //     line-height: 17px;
    //     border-radius: 8px;
    //     border: none;
    // }
}