nav.custom-navbar{
    position: sticky;
    top: 0;
    width: 100%;
    border-bottom: 1px solid var(--Tertiary-purple, #ECEBFF);
    background: #FFF;
    box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.05); 
    user-select: none;
    z-index: 2;
    div.container{
        height: 60px;
        max-width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        div.navbar-logo{
            height: max-content;
            a.logo-link{
                display: block;
                height: 30.6px;
                img{
                    width: 132px;
                    // margin-left: 12px;
                    // aspect-ratio: 4/1;
                    // height: 44px;
                }
            }
        }
        div.navItems{
            display: flex;
            align-items: center;
            justify-content: space-around;
            div.navItems-wrapper{
                display: flex;
                flex-direction: row;
                gap: 12px;
    
                margin-right: 16px;
        
                a.navItem{
                    text-decoration: none;
                    font-size: 16px;
                    color: rgba($color: #000000, $alpha: 0.8);
                    width: max-content;
                    padding: 10px;

                    &.active{
                        border-bottom: 6px solid rgba(82, 76, 159, 0.4);
                    }
                }
            }
            button.primary-btn{
                padding: 14px 14px;
                font-size: 16px;
                margin-top: 4px;
            }
        }
        .hamIcon{
            display: none;
            font-size: 24px;
            // margin-right: 12px;
            color: #514C9F;
            cursor: pointer;
        }
        div.sideItems{
            position: fixed;
            z-index: 3;
            top: 0;
            right: 0;
            height: 120vh;
            width: 100%;
            transform: translateX(100%);
            background: #fff;
            display: none;
            transition: transform 0.4s ease;

            div.closeIcon{
                display: flex;
                justify-content: flex-end;

                font-size: 25px;
                color: #514C9F;
                margin: 16px;
                padding-right: 6px;
                cursor: pointer;
            }
            
            div.navItems-wrapper{
                display: flex;
                flex-direction: column;
                gap: 32px;

                a.navItem{
                    text-decoration: none;
                    color: #000000;
                    padding: 10px 0 0 32px;
                }
            }
            button.primary-btn{
                margin: 42px 0 0 32px;
            }
        }
    }
}

@media only screen and (max-width: 991px){

    nav.custom-navbar{

        div.container{
            // max-width: 100%;
            max-width: 96%;

            div.navItems{
                div.navItems-wrapper{
                    margin-right: 12px;
                    a.navItem{
                        font-size: 14px;
                    }
                }
                // display: none;
                // font-size: 14px;
                button.primary-btn{
                    font-size: 14px;
                    padding: 12px 10px;
                    margin-top: 4px;
                }
            }
            // .hamIcon{
            //     // display: block;
            // }

        }
    }
}

@media (max-width: 768px) {
    nav.custom-navbar{
        div.container{
            div.navItems{
                display: none;
            }
            .hamIcon, div.sideItems{
                display: block;
            }
            div.sideItems{
                transform: translateX(100%);
            }
            div.sideItems.show{
                // width: 100%;
                transform: translateX(0%);
            }
        }
    }
}











/* div.navbar-area{
    width: 100%;
    position: fixed;
    z-index: 999;
    padding: 10px 0;
    background: transparent;
}

div.navbar-brand img.navbar-brand-img{
    width: 50%;
}
div.navbar-area div.navbar-brand img.navbar-brand-img{
    content: url('../../assets/footerLogo.png');
}
div.navbar-area.scrolled div.navbar-brand img.navbar-brand-img{
    content: url('../../assets/footerLogo.png');
}

div.navbar-area.scrolled{
    background: whitesmoke;
    transition: all 0.6s ease-in-out;
}

div.navbar-area.scrolled div.navItems>li{
    color: black;
}

div.navItems{
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.navItems>li{
    list-style: none;
    margin-right: 20px;
    color: white;
    font-size: 17px;
    font-weight: 500;
}

div.navItems>li>a{
    text-decoration: none;
    color: inherit;
}

div.nav-toggler-icon{
    display: none;
    padding-right: 16px;
}
div.navbar-area div.nav-toggler-icon{
    color: white;
    font-size: 25px;
    cursor: pointer;
}
div.navbar-area.scrolled div.nav-toggler-icon{
    color: black;
    font-size: 25px;
    cursor: pointer;   
}


div.custom-sidebar{
    height: 120%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background: linear-gradient(0deg, #ff2f2f 5.43%, #000460 92.78%), radial-gradient(100% 246.94% at 100% 0, #fff 0, #020063 100%), linear-gradient(143.18deg, #1400ff 0.45%, #3a0000), linear-gradient(167.55deg, #ff002e, #ff003d 74.04%), linear-gradient(351.18deg, #b25bba 7.52%, #1700a7 77.98%), linear-gradient(202.44deg, #a90000 12.99%, #00ffe0 87.21%), linear-gradient(165.77deg, #b7d500 15.35%, #20a 89.57%);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 30px;
}
div.close-btn-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 20px;
    cursor: pointer;
}
div.close-btn-wrapper .close-icon{
    font-size: 30px;
    color: white;
}
.sidebar-list{
    list-style: none;
    padding-left: 0;
}
ul.sidebar-item-child{
    list-style: none;
}
ul.sidebar-item-child li a{
    font-size: 1.2rem;

}
.custom-sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: white;
    display: block;
    transition: 0.3s;
}
.custom-sidebar a:hover {
    color: #f1f1f1;
}

.custom-sidebar.show{
    width: 100%;
}

@media only screen and (max-width: 991px){
    div.navItems{
        display: none;
        position: absolute;
        top: 90px;
        left: 0;
        flex-direction: column;
        width: 100%;
        background: whitesmoke;
        border: 1px solid red;
        max-height: 0;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
    }

    div.nav-toggler-icon{
        display: block;
    }
    div.navItems>li{
        display: block;
        width: 100%;
        padding: 10px;
        text-align: center;
        color: black;
    }
} */