$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;


main.mobileNumberVerification{
    // margin-top: 10px;
    padding: 0 10px;
    height: 100%;

    p{
        margin-bottom: 10px;
    }

    h3.mobileVerificationHeading{
        font-size: $size-L;
        font-weight: 700;
        padding-bottom: 20px;
    }

    p.mobileVerificationSubheading{
        font-size: $size-M;
        font-weight: 400;
        line-height: 17px;
        padding-bottom: 2px;
    }

    div.number-group{
        display: flex;
        // height: 77px;
        width: 100%;
        margin-bottom: 1rem;

        .number-group--leftHalf{
            height: 48px;
            width: 40px;
            display: flex;
            font-size: $size-M;
            font-weight: 600;
            align-items: center;
            justify-content: center;
            padding-left: 10px;
        }

        .number-group--rightHalf{
            width: 100%;
            padding-left: 12px;
            input{
                height: 48px;
                width: 100%;
                padding: 10px;
                font-size: $size-M;
                margin-right: 4px;
                border: none;
                border-radius: 4px;
                background: #ECEBFF;
                appearance: textfield;
            }
            p{
                font-size: $size-S;
                font-weight: 400;
                color: red;
                margin: 10px auto;
                display: none;
            }
        }
    }

    div.inputGroup{
        p{
            font-weight: 400;
            font-size: $size-M;
            line-height: 17px;
            // margin-top: 20px;
            padding-bottom: 0px;
        }
        input, select{
            padding: 10px;
            margin-bottom: 20px;

            width: 100%;
            height: 48px;
            
            background: #ECEBFF;
            border-radius: 4px;
            border: none;
        }
    }

    div.termsAndConditions{
        display: flex;
        align-items: flex-start;
        gap: 10px;
        user-select: none;
        margin-bottom: 1rem;
        // padding: 0px 10px 10px 2px;
        // border-radius: 5px;
        // outline: 1px solid red;

        input, p{
            font-size: $size-M;
            // padding-left: 10px;
            font-weight: 400;
        }
        input[type="checkbox"]{
            min-width: 16px;
            min-height: 16px;
            margin-top: 4px;
            border: 1px solid rgba(0, 0, 0, 0.4);
            border-radius: 2px;
            accent-color: #514C9F;
        }

        label{
            a.termsAndCond{
                font-weight: 600;
                color: black;
                text-decoration-line: underline;
            }
        }

    }

    

    button.sendOTP{
        margin-top: 10px;
    }
    //     width: 100%;
    //     height: 48px;
    //     background: #514C9F;
    //     margin-top: 20px;
    //     color: #fff;
    //     font-weight: 700;
    //     font-size: 14px;
    //     border-radius: 8px;
    //     border: none;
    // }

}