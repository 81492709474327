$size-XL : 24.75px;
$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main.loanAppSuccessful{
    padding: 10px;
    text-align: center;

    // padding-top: 5rem;

    div.textGroup{
        padding: 24px 4px 8px 4px;
        h3{
            font-weight: 700;
            font-size: $size-L;
            line-height: 19px;
            margin-bottom: 10px;

            color: #1C8769;
        }
        p{
            padding-top: 4px;
            margin-bottom: 10px;
        }
    }

    div.btn-group{
        margin-top: 32px;
        height: 87px;

        background: #FAE1CD;
        border-radius: 4px;

        p{
            padding: 8px 0 10px 0;
            color: #514C9F;
        }

        button{
            padding: 10px;
            width: 100%;
            height: 48px;

            margin-top: 10px;

            font-weight: 700;
            font-size: $size-M;
            line-height: 17px;
            color: white;

            background: #514C9F;
            border-radius: 8px;
            border: none;
        }
        
    }


}