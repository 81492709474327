$size-XL : 24.75px;
$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main.approvedLoanLimitNeg{
    padding: 0 10px;
    text-align: center;

    padding-top: 4rem;
    
    h3{
        padding: 24px 0 10px 0;
        font-weight: 700;
        font-size: $size-L;
        line-height: 19px;

        color: #EA6B0C;
    }

    p.text{
        margin: 18px 0;
        font-size: $size-M;
        line-height: 18px;

        color: rgba(0, 0, 0, 0.8)
    }

    div.msgBox{
        padding: 10px;

        color: #514C9F;
        background: #ECEBFF;
        border-radius: 10px;

        font-size: $size-M;
        line-height: 20px;
    }
}