$size-XL : 24.75px;
$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main{
    p.subheading{
        font-size: $size-M;
        margin: 30px 0 15px 0;
    }

    div.msgBox{
        background: #FAE1CD;
        padding: 10px;
        border-radius: 4px;

        // color: #514C9F;

        p{
            margin: 8px 0;
        }
    }

    div.offerCard{
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        // background: var(--tertiary-purple, #ECEBFF);
        border: 1px solid #ccc;
        margin-top: 10px;
        color: rgba($color: #000000, $alpha: 0.8);
        padding: 10px 0;
        user-select: none;
        cursor: pointer;
        
        div.select{
            width: 20%;

            div.selectCircle{
                background: white;
                height: 25px;
                width: 25px;
                border-radius: 50%;
                border: 2px solid #514C9F;
                margin: 16px auto;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                div.innerCirle{
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                }
            }
        }

        div.offerContentWrapper{
            width: 100%;
            padding: 0 12px;
            div.offerContent{
                display: flex;
                flex-direction: row;
                padding: 5px 0;
                // outline: 1px solid red;
                // justify-content: space-around;
    
                div.offerContentLeft, div.offerContentRight{
                    width: 50%;
                    span.offerCardSpan{
                        display: block;
                        font-size: 16px;
                        padding: 4px;
                    }
                    span.offerCardSpan.largeText{
                        font-size: 18px;
                    }
                }
            }
        }

        &.selected{
            div.select{
                div.selectCircle{
                    div.innerCirle{
                        background: #514C9F;
                    }
                }
            }
            
        }
    }

    
}