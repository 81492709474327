@font-face {
  font-family: 'sigmundpro-semibold';
  src: url('./website/assets/Fonts/sigmundpro-semibold-webfont.woff2') format('woff2'),
       url('./website/assets/Fonts/sigmundpro-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  margin: 0;
  // font-family: "sigmundpro-semibold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  main.screenContainer{
    padding: 0 18px 18px 18px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  scroll-behavior: smooth;
  // scroll-margin: 6rem;
  scroll-padding-top: 5rem;
}
.object-contain{
  padding: 5px !important;
}

// input{
//   outline: none;
// }
// input:valid{
//   background: white !important;
//   outline: 1px solid black;
// }