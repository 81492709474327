$primary-color:#514C9F;
$primary-orange: #EA6B0C;
$primary-green :#176734;



div.upper-section{
    padding-left: 16px;
    // margin-top: -10px;
    h1.heading{
        color: $primary-color;
        font-size: 45px;
        font-weight: 700;
        line-height: 45px;
    }
    
    p.subheading{
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        margin-top: 12px;
    }
}

div.middle-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    padding: 0 10px;

    img.main-image{
        width: 100%;
        padding: 8px;
    }
}

div.lower-section{
    margin-top: 20px;
    margin: 10px;

    div.line{
        display: flex;
        height: 46px;
        width: 100%;

        font-weight: 500;
        font-size: 18px;

        border-radius: 8px;

        padding: 10px 20px;
        margin-top: 10px;
        align-items: center;

    }

    div.line1{
        color: $primary-color;
        background: #ECEBFF;
        border-left: 6px solid #514C9F;
    }
    div.line2{
        color: $primary-orange;
        background: rgba(234, 108, 12, 0.2);
        border-left: 6px solid $primary-orange;
    }
    div.line3{
        color: $primary-green;
        background: rgba(23, 103, 52, 0.2);
        border-left: 6px solid $primary-green;
    }

    // button{
    //     width: 100%;
    //     height: 42px;
    //     background: $primary-color;
    //     margin-top: 20px;
    //     color: #fff;
    //     font-weight: 700;
    //     font-size: 14px;
    //     border-radius: 8px;
    //     border: none;
    // }
}
