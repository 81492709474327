$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main.employmentDetails{
    padding: 0px 12px 12px 12px;
    margin-bottom: 2rem;
    h3{
        padding: 24px 0 20px 0;

        font-weight: 700;
        font-size: $size-L;
        line-height: 19px;
    }

    div{
        margin-bottom: 16px;
        p{
            font-weight: 400;
            font-size: $size-M;
            line-height: 17px;

            padding-top: 4px;
            margin-bottom: 10px;
        }
        input, select{
            padding: 10px;
            
            width: 100%;
            height: 48px;

            font-size: $size-M; // optional

            appearance: textfield;
            
            background: #ECEBFF;
            border-radius: 4px;
            border: none;
        }
    }

    div.timeInJob, div.totalExp{
        div.inputGroup{
            display: flex;
            align-items: baseline;
    
            input, select{
                width: 60px;
                height: 40px;

                text-align: center;

                padding: 10px;
                margin-right: 4px;
                
                background: #ECEBFF;
                border-radius: 4px;
            }
            p{
                margin-right: 4px;
            }
        }
    }

    

    div.consentBox{
        display: flex;

        input{
            width: 16px;
            height: 16px;

            margin-left: 5px;
            margin-right: 10px;

            border: 1px solid rgba(0, 0, 0, 0.4);
            border-radius: 2px;
            accent-color: #514C9F;
        }
        label{
            font-weight: 400;
            font-size: $size-S;
            line-height: 16px;
        }
    }

    // button.submit{
    //     width: 100%;
    //     height: 48px;
    //     background: #514C9F;
    //     margin-top: 20px;
    //     color: #fff;
    //     font-weight: 700;
    //     font-size: 14px;
    //     line-height: 17px;
    //     border-radius: 8px;
    //     border: none;
    // }

}

