img#logo{
    height: 28px;
    // width: 115px;
  }
  
  div#header{
    padding: 10px 14px 10px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  
    div.user{
      width: 40px;
      height: 40px;
      padding: 2px;
      border-radius: 50%;
      border: 2px solid #F1A164;
      
      display: flex;
      align-items: center;
      justify-items: center;
      overflow: hidden;

      user-select: none;
      cursor: pointer;
  
      p.initials{
        width: 100%;
        height: 100%;
        font-weight: 600;
        color: #514C9F;
        border-radius: 50%;
        font-size: 18px;
        letter-spacing: 0.75px;
        background: #ECEBFF;
        margin: 0;
  
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }