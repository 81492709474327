div.stepCard {
  padding: 10px 10px 15px 15px;
  width: 100%;
  // margin-bottom: 1rem;
  // width: max-content;
  text-align: left;
  // display: flex;
  // justify-content: center;

  background: #fae1cd;
  border-radius: 10px;

  p {
    padding: 8px 0;
    margin-bottom: 16px;

    // text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  ul {
    text-align: left;

    // padding-left: 10px;
    li {
      position: relative;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 14px;
      min-height: 22px;
      // padding-top: 3px;

      list-style: none;

      div.encircle {
        display: inline;
        position: relative;
        top: -2px;
        // aspect-ratio: 1/1;
        padding: 3px 6px;
        margin-right: 10px;
        border: 1px solid black;
        border-radius: 50%;

        font-weight: 400;
        font-size: 9px;
        line-height: 10px;
        // display: flex;
        // flex-direction: row;
        // align-items: end;
      }

      span.v-line {
        display: inline-block;
        position: absolute;
        left: 9px;
        top: 19.5px;
        height: 17.5px;
        width: 1px;
        border-right: 1px solid black;
      }

      div.stepName {
        display: inline-flex;
        flex-direction: row;
        width: 90%;
        height: max-content;
        align-items: center;
        justify-content: space-between;

        span.step {
          width: 50%;
        }

        .boltIcon {
          font-size: 16px;
          padding-top: 5px;
          fill: #ffffe1;

          path:last-child {
            stroke: #ff9300;
            stroke-width: 1.2px;
            stroke-dasharray: 1, 0;
          }
        }
      }
    }

    li:nth-child(3) {
      span.v-line {
        height: 36px;
        top: 40;
      }
    }
  }
}

.fadeInUpAnimate {
  animation-name: fadeInUp;
  animation-duration: 4s;
  animation-delay: 0.7s;
  animation-iteration-count: 1;
}

@keyframes fadeInUp {
  0% {
    margin-top: 2rem;
    opacity: 0;
  }

  30% {
    margin-top: 0;
    opacity: 1;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}

a#disabled {
  pointer-events: none;
  filter: grayscale(1) brightness(220%);
}

div.inputGroup {
  p {
    font-weight: 400;
    font-size: 15.75px;
    line-height: 17px;
    // margin-top: 20px;
    padding-bottom: 0px;
  }

  input,
  select {
    padding: 10px;
    margin-bottom: 20px;

    width: 100%;
    height: 48px;

    background: #ecebff;
    border-radius: 4px;
    border: none;
  }
}

div.stepsnew {
  padding: 10px 10px 15px 15px;
  width: 100%;
  // margin-bottom: 1rem;
  // width: max-content;
  text-align: left;


  p {
    padding: 8px 0;
    margin-bottom: 16px;

    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  ul {
    text-align: left;

    // padding-left: 10px;
    li {
      position: relative;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 14px;
      min-height: 22px;
      // padding-top: 3px;
      display: flex;
      list-style: none;

      div.encircle {
        width: 24px;
        height: 40px;
        padding: 9px 8.5px;
        background: #ECEBFF;
        border-radius: 10px;
        margin-right: 15px;
        z-index: 2;

      }

      span.v-line {
        display: inline-block;
        position: absolute;
        left: 11px;
        top: 38.5px;
        ;
        height: 20px;
        width: 1px;
        border-right: 1px dashed #8a8a8a;
      }

      div.stepName {
        display: inline-flex;
        flex-direction: row;
        width: 80%;
        height: max-content;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        span.step {
          width: 50%;
        }

        .boltIcon {
          font-size: 16px;
          padding-top: 5px;
          fill: #ffffe1;

          path:last-child {
            stroke: #ff9300;
            stroke-width: 1.2px;
            stroke-dasharray: 1, 0;
          }
        }
      }
    }

    li:nth-child(2) {
      span.v-line {
        height: 36px;
        top: 40;
      }
    }
  }
}

.refresh-btn-user {
  margin-top: 10px;
  padding: 10px;
  background: rgb(80, 76, 154);
  color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 5px;
  border: none;
  font-weight: 700;

}