main.emandateWebview{
    padding: 0 10px;
    height: calc(100vh - 82px);

    display: flex;
    flex-direction: column;
    align-items: center;
    // iframe{
    //     height: 100%;
    //     width: 100%;
    // }

    div.symbol{
        
        .icon{
            padding: 0;
            height: 100px;
            width: 100px;
            // background: #ECEBFF;
            border-radius: 50%;
            
        }
        .icon.red{
            color: #fe8787;
        }
        .icon.green{
            color: rgb(32, 180, 32);
        }
    }

    div.kycStatus{
        width: 30ch;

        color: #514C9F;
        // text-align: center;
        // margin-left: 1rem;
        margin-top: 4rem;

        span.kycStatusValue.red{
            color: red;
        }
        span.kycStatusValue.green{
            color: rgb(32, 180, 32);
        }
    }

    div#redirect{
        visibility: hidden;
        margin-top: 4rem;
    }

    div.refreshKycStatus{
        width: 60%;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;
        color: #514C9F;
        background: #ECEBFF;

        margin-top: 6rem;

        border: 2px solid #514C9F;
        border-radius: 4px;

        text-align: center;

        cursor: pointer;
        
    }
    a.tryAgain{
        margin-top: 2rem;
    }
}