$size-XL : 24.75px;
$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main.bankDetails{
    padding: 0px 12px 12px 12px;
    // margin-bottom: 2rem;
    h3{
        padding: 24px 0 20px 0;

        font-weight: 700;
        font-size: $size-L;
        line-height: 19px;
    }

    p.note{
        font-size: $size-S;
        margin-bottom: 10px;
    }

    div{
        padding-left: 4px;
        margin-bottom: 16px;
        p{
            font-weight: 400;
            font-size: $size-M;
            line-height: 17px;

            padding-top: 4px;
            margin-bottom: 10px;
        }
        input{
            padding: 10px;

            appearance: textfield;
            
            width: 100%;
            height: 48px;
            
            background: #ECEBFF;
            border-radius: 4px;
            border: none;
        }

        // input#accountNumber:focus{
        //     type:
        // } 
        // input#confirmAccountNumber{

        // }


    }



    // div.IFScode{
    //     input:valid{
    //         text-transform: uppercase;
    //     }
    //     // input{
    //     //     text-transform: lowercase;
    //     // }
    // }

    // button.submit{
    //     width: 100%;
    //     height: 48px;
    //     background: #514C9F;
    //     margin-top: 26px;
    //     color: #fff;
    //     font-weight: 700;
    //     font-size: 14px;
    //     line-height: 17px;
    //     border-radius: 8px;
    //     border: none;
    //     cursor: pointer;
    // }

}