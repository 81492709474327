$size-XL : 24.75px;
$size-L : 18px;
$size-M : 15.75px;
$size-S : 13.5px;

main.congrats{
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    padding: 0 10px 10px 10px;

    img{
        // margin-top: 1rem;
        max-width: 50%;
    }
    // div.card{
    //     width: 100%;

    //     padding: 10px;
    //     margin-bottom: 14px;

    //     color: white;

    //     /* Green gradient */
    //     background: linear-gradient(99.82deg, #6DB29F 0%, #1C8769 99.49%);
    //     border-radius: 10px;

    //     p.referenceID{
    //         font-weight: 400;
    //         font-size: $size-S;
    //         line-height: 16px;
    //         margin-bottom: 14px;
    //         color: white;
    //     }
    //     p.congrats{
    //         padding: 4px 0;
    //         margin-bottom: 14px;
    //         font-weight: 700;
    //         font-size: $size-L;
    //         line-height: 24px;
    //         color: white;
    //     }
    //     div.amount{
    //         padding: 14px 10px;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;

    //         width: 100%;
    //         height: 52px;

    //         font-weight: 700;
    //         font-size: $size-XL;
    //         line-height: 24px;

    //         color: #1C8769;

    //         background: #FFFFFF;
    //         border-radius: 4px;
    //     }
    // }
    p.title{
        margin: 16px 0 8px 0;
        font-weight: 700;
        font-size: $size-L;
        line-height: 19px;

        /* primary green */
        color: #1C8769;
    }
    p.subtitle{
        
        padding: 8px 0;

        text-align: left;
        font-weight: 400;
        font-size: $size-M;
        line-height: 18px;

        color: rgba(0, 0, 0, 0.8);
    }

    // div{
        // margin-top: 1rem;
    // }
    
    div.stepCard{
        padding: 10px 10px 15px 15px;
        width: 100%;
        // margin-bottom: 1rem;
        // width: max-content;
        text-align: left;
        // display: flex;
        // justify-content: center;

        background: #FAE1CD;
        border-radius: 10px;

        p{
            padding: 8px 0;
            margin-bottom: 16px;

            // text-align: center;
            font-weight: 400;
            font-size: $size-M;
            line-height: 18px;

            color: #514C9F;
        }
        
        ul{
            text-align: left;
            padding-left: 10px;
            li{
                font-size: $size-M;
                line-height: 20px;
                color: #514C9F;
                margin-bottom: 10px;
                // padding-top: 3px;

                list-style: none;

                .encircle{
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 18px;
                    aspect-ratio: 1/1;
                    position: relative;
                    
                    top: -2px;
                    // padding: 2px 5px;
                    margin-right: 10px;
                    color: #514C9F;
                    border: 1px solid #514C9F;
                    border-radius: 50%;

                    font-weight: 400;
                    font-size: 9px;
                    line-height: 10px;

                }
                span.v-line{
                    display: inline-block;
                    position: relative;
                    left: -19px;
                    top:18px;
                    height: 14px;
                    width: 1px;
                    border-right: 1px solid #514C9F;

                }
            }
        }
    }
}
div.stepName{
    display: inline-flex;
    flex-direction: row;
    width: 90%;
    height: max-content;
    align-items: center;
    justify-content: space-between;

    .boltIcon{
        color: goldenrod;
        font-size: 16px;
        padding-top: 5px;
    }

}