div.verifyPrompt{
    width: 100%;
    padding: 14px;
    margin-top: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;

    background: #FAE1CD;
    color: #EA6B0C;
}